import axios from 'axios'
import {ElMessage} from "element-plus";
import store from "../store";
import router from "../router";
import VueEvent from "./global_event";
import Cookies from "js-cookie";

// 请求防抖
import { reqTimeOut, stopRepeatRequest, allowRequest } from '@/utils/aixosDebounce'

// 使用vite时，env引入方式变为import.meta.env.xxx
// const BASE_URL = import.meta.env.VITE_APP_BASE_URL
// 开发时使用代理

// const BASE_URL = '/api'

console.log(import.meta.env.VITE_APP_BASE_URL,'====---====');


const service = axios.create({
    baseURL: import.meta.env.PROD ? import.meta.env.VITE_APP_BASE_URL : '/api',
    timeout: 15000,
    headers: {
        'content-type': 'application/json',
    },
    // cross origin
    withCredentials: false,
})

// 处理请求事件
service.interceptors.request.use(config => {
    if (!config.headers['Authorization']) {
        config.headers['Authorization'] = 'Bearer ' + localStorage.access_token
    }
    let cancel
    config.cancelToken = new axios.CancelToken(c => (cancel = c))
    // 阻止重复请求。当上个请求未完成时，相同的请求不会进行
    const { url, method, params, data } = config
    let paramsObj = method == 'get' ? params : data
    stopRepeatRequest(url, paramsObj ?? '', cancel, `请不要频繁操作`)
    return config
})

const allowErrorCodeList = [202, 203, 204, 205, 211, 301, 0]

// 处理响应事件
service.interceptors.response.use(response => {
    // 增加延迟，相同请求不得在短时间内重复发送
    setTimeout(() => {
        allowRequest(response.config.url)
    }, reqTimeOut)
    // const code = response.status
    const res = response.data
    // HTTP 状态码不为200
    const {errorCode, msg} = res
    if (allowErrorCodeList.includes(errorCode)) {
        return res
    }
    return Promise.reject(msg || '请求错误')
}, error => {
    if (axios.isCancel(error)) {
        return Promise.reject(error)
        // 区分错误是因为被防抖阻止还是404
    } else {
        // 取消了延迟，相同请求不得在短时间内重复发送，除非上一次的请求404了
        console.log(error, 'error')
        allowRequest(error.config.url)
        if (error.response.status === 401) {
            const url = Cookies.get("redirect_url")
            if (!url) {
                const urlSplit = window.location.href.split("/#")
                if (urlSplit.length > 1) {
                    Cookies.set("redirect_url", urlSplit[1])
                }
            }
            store.commit("LOGIN_OUT")
            router.push({
                path: '/'
            }).then(r => {
                VueEvent.emit("showLogin")
            })
            return Promise.resolve()
        }
    }
    const errMsg = error.response.data.msg || error.response.data.message || error.message || '请求失败'
    // ElMessage.error(errMsg)
    console.log('errMsg',errMsg)
    return Promise.reject(errMsg)
})

export default service
