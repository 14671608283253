<template>
  <el-affix :offset="0" style="width: 100%">
    <div
      :class="`header-wrapper ${isSearchPage ? 'search-page' : ''}`"
      :style="bgColor ? 'backGround:#fff' : 'backGround:transparent'"
    >
      <div class="nav">
        <div class="nav-container">
          <div class="left-tool">
            <router-link to="/" class="link" title="返回首页">
              <img
                class="logo"
                :src="bgColor || isSearchPage ? LogoIcon : LogoWhiteIcon"
                alt="返回首页"
              />
            </router-link>
            <div class="tab-group" @mouseleave="hoverIndex = -1">
              <div
                class="tabs-res"
                :class="headerClass(item.link)"
                v-for="(item, index) in headerData"
                :key="index"
              >
                <router-link :to="item.link" @mouseenter="hoverIndex = index"
                  >{{ item.text }}
                </router-link>
                <div
                  class="active-bar"
                  v-if="currentRoute==(item.link)"
                ></div>
              </div>

              <!--            <div class="follow-bar" :style="activeBarFollow"></div>-->
            </div>
          </div>
          <div class="right-tool">
            <div class="search">
              <div
                class="basis-header-left"
                :style="bgColor || isSearchPage ? 'outline:1px solid rgba(3, 152, 254, 1)' : ''"
                :class="bgColor || isSearchPage ? 'inputActive' : ''"
                ref="dropDown"
              >
                <input
                  type="text"
                  v-model="keyword"
                  placeholder="会议/课程/专家"
                  @click.stop.prevent="dropDownShow"
                  maxlength="16"
                  ref="input"
                  @keypress.enter="getSearchResult"
                />
                <i class="el-icon-search" ref="icon" @click="getSearchResult"></i>
              </div>
              <!--            <button class="btn" @click="getSearchResult">搜索</button>-->
              <!-- 搜索下拉框 dropShow-->
              <div
                class="drop-down"
                :style="bgColor || isSearchPage ? 'outline:1px solid rgba(3, 152, 254, 1)' : ''"
                v-show="dropShow"
                @mouseleave.stop="dropDownHid"
              >
                <div
                  class="history-title mt-18"
                  v-if="history && history.length > 0"
                >
                  <div>搜索记录</div>
                  <img
                    src="../../assets/img/search/delete.png"
                    alt=""
                    @click="clearHistory"
                  />
                </div>
                <div class="history-body" v-if="history && history.length > 0">
                  <span
                    v-for="(his, index) in history"
                    :key="index"
                    @click="clickToSearch(his)"
                    >{{ his }}
                  </span>
                </div>
                <div
                  class="history-title"
                  :class="history.length <= 0 ? 'mt-18' : ''"
                  v-if="hot && hot.length > 0"
                >
                  <div class="hot-tool">
                    热门搜索
                    <img src="@assets/img/common/hot-icon.png" alt="" />
                  </div>
                </div>
                <div class="history-body" v-if="hot && hot.length > 0">
                  <span
                    v-for="(item, index) in hot"
                    :key="index"
                    @click="clickToSearch(item)"
                    >{{ item.content }}
                  </span>
                </div>
              </div>
            </div>
            <div v-if="userInfo" class="user">
              <div
                class="integral"
                :style="bgColor || isSearchPage ? 'background: rgba(252, 168, 43,.2)' : ''"
                @click="$router.push({ path: '/users/index' })"
              >
                <img src="@assets/img/common/sign-icon.png" alt="" />
                <!--              <p>{{ userInfo.points }}积分</p>-->
              </div>
              <el-popover
                placement="bottom-start"
                width="106px"
                offset="0"
                :show-arrow="false"
                popper-class="popover-class"
                trigger="hover"
                @show="showPopover"
                @hide="hidePopover"
              >
                <template #reference>
                  <div class="user-info">
                    <img :src="userInfo.avatar" class="user-img" />
                    <p
                      class="user-p"
                      :style="bgColor || isSearchPage ? 'color:rgba(3, 152, 254, 1)' : ''"
                    >
                      {{ userInfo.name ? userInfo.name : "未实名" }}
                    </p>
                    <img v-if="isShowPopover" :src="headerStyle('up')" />
                    <img v-else :src="headerStyle('down')" />
                  </div>
                </template>
                <ul class="user-ul">
                  <li
                    class="user-li"
                    @click="$router.push({ path: '/users/index' })"
                  >
                    <img src="@assets/img/common/user-avatar.png" />
                    <span>个人中心</span>
                  </li>
                  <li
                    class="user-li new-msg"
                    @click="
                      $router.push({
                        path: '/users/index',
                        query: { mark: 'msg' },
                      })
                    "
                    :class="{ 'new-msg': message && message.data.length > 0 }"
                  >
                    <img src="@assets/img/common/user-msg.png" />
                    <span>系统消息</span>
                  </li>
                  <li class="user-li" @click="outLogin">
                    <img src="@assets/img/common/user-out.png" />
                    <span>退出账号</span>
                  </li>
                </ul>
              </el-popover>
            </div>
            <div
              v-else
              class="login-btn"
              :style="
                bgColor ? 'backGround:rgba(3,152,254,0.1)' : 'backGround:#fff'
              "
              @click="loginModalVisible = true"
            >
              <img src="@assets/img/common/user-avatar.png" alt="" />
              <!--              <p>{{ userInfo.points }}积分</p>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-affix>
  <!--登录认证等弹窗-->
  <Login
    v-model:visible="loginModalVisible"
    @showAgreement="loadAgreement"
    @needCertification="certificationVisible = true"
    @needIdentity="identityVisible = true"
  ></Login>
  <Certification
    v-if="certificationVisible"
    @needIdentity="identityVisible = true"
    :isEdit="certificationFlag"
    @close="closeCertification"
  ></Certification>
  <Identity v-if="identityVisible" :isEdit="identityFlag"></Identity>

  <!--协议弹窗-->
  <el-dialog
    title="协议查看"
    custom-class="agreement"
    v-model="agreementVisible"
    destroy-on-close
  >
    <div
      class="agreement-content"
      v-html="agreementContent"
      v-loading="loading"
    ></div>
  </el-dialog>
  <charge-modal />
  <transition name="slide-fade">
    <complete-information
      v-if="formVisible"
      :meeting-form="formInfo"
      @close-dialog="formVisible = false"
    />
  </transition>
</template>
<script>
import Login from "../login/login.vue";
import Certification from "../auth/certification.vue";
import Identity from "../auth/identity.vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { headerData } from "../../assets/js/config";
import { useStore } from "vuex";
import {computed, nextTick, onMounted, onUnmounted, ref, watch} from "vue";
import VueEvent from "../../utils/global_event";
import { getHotSearch } from "../../api/search_api";
import { useRouter, useRoute } from "vue-router";
import { getAimedAgreement } from "../../api/common_api";
import useRegDialog from "../../hooks/common/useRegDialog";
import {
  auth,
  profile,
  unreadNotifications,
  allRead,
} from "../../api/personal_api";
import ChargeModal from "../payment/charge-modal.vue";
import CompleteInformation from "../../components/auth/complete-information.vue";
import flag from "@element-plus/icons/lib/Flag";
/*带颜色的logo*/
const LogoIcon = new URL(`../../assets/img/common/logo.png`, import.meta.url)
  .href;
/*白色的logo*/
const LogoWhiteIcon = new URL(
  `../../assets/img/common/logo-white.png`,
  import.meta.url
).href;
export default {
  name: "basis-header",
  props: {
    type: {
      type: String,
      default: "home",
      validator(value) {
        // The value must match one of these strings
        return ['home', 'search'].includes(value)
      },
    },
  },
  components: {
    ChargeModal,
    Identity,
    Certification,
    Login,
    CompleteInformation,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const icon = ref(null);
    const input = ref(null);
    const dropDown = ref(null);
    const hot = ref(null);
    const history = ref([]);
    const dropShow = ref(false);
    const agreementVisible = ref(false);
    const agreementContent = ref("");
    const num = ref(1);
    const message = ref(null);
    const count = ref(0)
    // const formVisible = ref(false);
    // const formInfo = ref(null);
    const loadAgreement = async (id) => {
      const { data } = await getAimedAgreement(id);
      agreementContent.value = data.content;
      agreementVisible.value = true;
    };
    // 监听事件
    const certificationVisible = ref(false);
    // 是否是变更 flase：正常实名 true：变更
    const certificationFlag = ref(false);
    const identityVisible = ref(false);
    // 是否是变更 flase：正常身份认证 true：变更
    const identityFlag = ref(false);
    const loginModalVisible = ref(false);
    // 背景颜色 false 透明色 true 白色
    const bgColor = ref(false);
    /* header顶部样式*/
    const headerClass = computed(() => {
      return (link) => {
        if (['/guidelines/index', '/regulations/index', '/regulations/detail', '/guidelines/detail'].includes(route.path)) { // 白底全灰字header
          return "tab-gray";
        }
        if (['/live/detail', '/index/search','/live/live', '/index/experts/index', '/courses/detail', '/index/experts/detail'].includes(route.path)) { // 白底灰字激活蓝字header
          if (currentRoute.value.startsWith(link)) {
            return "active-blue";
          } else {
            return "tab-gray";
          }
        }
        if (bgColor.value || isSearchPage.value) {
          return "active-blue";
        } else {
          return "active";
        }
      };
    });
    const headerStyle = computed(() => {
      return (type) => {
        return new URL(
          `/src/assets/img/common/select-${type}${
              (bgColor.value || isSearchPage.value) ? "-selected" : ""
          }.png`,
          import.meta.url
        ).href;
      };
    });
    /**  hooks函数 start */
    // 完善信息弹窗公共函数
    const { getRegListCom, formVisible, formInfo } = useRegDialog();
    /** hooks函数 end */
    // 是否是搜索页面
    const isSearchPage = ref(false)
    watch(
      () => [route.path, route.query, loginModalVisible.value],
      async ([newPath, newQuery, newLogin], [oldPath, oldQuery, oldLogin]) => {
        if (route.path.startsWith("/integral/index")) {
          const { data } = await profile();
          store.commit("USER_LOGIN", {
            userInfo: data,
          });
        } else if (route.path.startsWith("/")) {
          if (localStorage.access_token) {
            const { data } = await unreadNotifications();
            message.value = data;
          }
        }
        if (oldQuery.mark === "msg") {
          await allRead();
        }

        if (oldLogin === true) {
          if (localStorage.access_token) {
            const { data } = await unreadNotifications();
            message.value = data;
          }
        }
      }
    );
    const keyword = ref("");
    // 从本地取得搜索历史
    history.value = JSON.parse(localStorage.getItem("history")) || [];
    const clickToSearch = (e) => {
      keyword.value = e.content || e;
      searchHistory();
    };
    // 点击搜索按钮触发单击事件
    const getSearchResult = async (e) => {
      searchHistory();
    };
    const userInfo = computed(() => store.getters.getUserInfo);
    // 将搜索栏的内容添加到localStorage
    const searchHistory = () => {
      if (keyword.value.trim() === "") {
        router.push({
          path: '/index'
        })
        ElMessage.warning("请输入关键字");
        return;
      }
      if (!localStorage.access_token) {
        ElMessage.error("请先登录后使用");
        loginModalVisible.value = true;
        return;
      }
      if (history.value.indexOf(keyword.value) === -1) {
        history.value.unshift(keyword.value);
        localStorage.history = JSON.stringify(history.value);
      } else {
        history.value.splice(history.value.indexOf(keyword.value), 1);
        history.value.unshift(keyword.value);
        localStorage.history = JSON.stringify(history.value);
      }
      // c 是为了解决相同路由下，不请求搜索接口的问题
      router.replace({
        path: "/index/search",
        query: { key: keyword.value, c: count.value },
      });
      count.value++
    };

    const closeCertification = () => {
      certificationVisible.value = false;
      // formVisible.value = true;
    };

    const clearHistory = () => {
      ElMessageBox.confirm("确定要清空历史记录吗?", "提示", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          clear();
        })
        .catch((e) => {});
    };

    // 单击清空搜索历史
    const clear = () => {
      localStorage.removeItem("history");
      history.value = JSON.parse(localStorage.getItem("history")) || [];
    };

    // 下拉框显示
    const dropDownShow = () => {
      dropShow.value = true;
      // icon.value.style.display = "none";
      // input.value.placeholder = "请输入搜索内容";
      dropDown.value.style.width = "296px";
      dropDown.value.style.borderRadius = "20px 20px 0 0";
    };

    // 下拉框隐藏
    const dropDownHid = () => {
      dropShow.value = false;
      // icon.value.style.display = "inline-block";
      // input.value.placeholder = "关键字搜索";
      // input.value.style.marginLeft = "10px";
      dropDown.value.style.borderRadius = "20px ";
      dropDown.value.style.width = "196px";
    };
    const handleScroll = () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
      scrollTop > 30 ? (bgColor.value = true) : (bgColor.value = false);
      if (!localStorage.access_token && route.path !== '/index') {
        bgColor.value = true
      }
    };
    onMounted(async () => {
      /*  监听顶部导航栏的样式*/
      window.addEventListener("scroll", handleScroll, true);
      try {
        VueEvent.on("triggerCertification", (flag) => {
          certificationVisible.value = true;
          certificationFlag.value = flag;
        });
        VueEvent.on("triggerIdentity", (val) => {
          identityVisible.value = val;
          identityFlag.value = val;
        });
        VueEvent.on("triggerIdentitys", (val) => {
          identityVisible.value = val[0];
          identityFlag.value = val[1];
        });
        VueEvent.on("showLogin", () => {
          if (loginModalVisible.value) {
            return;
          }
          loginModalVisible.value = true;
        });
        document.addEventListener("click", () => {
          if (dropShow.value) {
            dropDownHid();
          }
        });
        const { data } = await getHotSearch();
        hot.value = data.slice(0, 5);
        // 无token不登录
        const token = localStorage.access_token;
        if (!token) {
          bgColor.value = true
          handleScroll()
          return;
        }
        const profileData = await profile();
        const authInfo = await auth();
        store.commit("USER_LOGIN", {
          userInfo: profileData.data,
        });
        store.commit("SET_AUTH_INFO", {
          authInfo: authInfo.data,
        });
        /**
         * 完善信息弹窗权限添加
         */
        if (window.localStorage.perfectInfo !== "1") {
          await getRegListCom();
        }
      } catch (e) {
        ElMessage.error(e);
      }
    });
    // 销毁事件
    onUnmounted(() => {
      VueEvent.off("triggerCertification", () => void 0);
      VueEvent.off("triggerIdentity", () => void 0);
      VueEvent.off("showLogin", () => void 0);
      document.removeEventListener("click");
    });
    // 退出账号
    const outLogin = () => {
      router
        .push({
          path: "/",
        })
        .then((r) => {
          store.commit("LOGIN_OUT");
        });
      localStorage.removeItem("perfectInfo");
    };

    const hoverIndex = ref(-1);
    const activeBarFollow = computed(() => {
      return {
        left: hoverIndex.value * 133 + "px",
      };
    });
    const currentRoute = ref("");
    const whiteHeaderRoutes = ['/index/experts/index', '/index/search', '/index/experts/detail', '/guidelines/index', '/regulations/index',
      '/live/detail', '/live/live', '/courses/detail', '/courses/xxz', '/regulations/detail', '/guidelines/detail','/users/index','/integral/index',
      '/integral/product-info', '/integral/video','/integral/submit-order','/pic-live'
    ] // 白色底蓝字header的路由
    watch(route, (to, from) => {
      currentRoute.value = to.path;
      if (!currentRoute.value.startsWith("/index/search")) {
        keyword.value = "";
      } else {
        keyword.value = route.query.key;
      }

      isSearchPage.value = whiteHeaderRoutes.includes(to.path);

      if (!localStorage.access_token && route.path === '/index') {
        bgColor.value = false
      }
    });
    const isShowPopover = ref(false);
    const showPopover = () => {
      isShowPopover.value = true;
    };
    const hidePopover = () => {
      isShowPopover.value = false;
    };
    return {
      isShowPopover,
      showPopover,
      hidePopover,
      hoverIndex,
      currentRoute,
      userInfo,
      certificationVisible,
      certificationFlag,
      identityVisible,
      identityFlag,
      loginModalVisible,
      hot,
      history,
      dropDown,
      dropShow,
      icon,
      input,
      keyword,
      activeBarFollow,
      agreementVisible,
      agreementContent,
      num,
      headerStyle,
      appSetting: computed(() => store.getters.getAppSetting),
      loading: ref(false),
      headerData,
      loadAgreement,
      getSearchResult,
      dropDownShow,
      dropDownHid,
      clear,
      clearHistory,
      clickToSearch,
      outLogin,
      closeCertification,
      message,
      formVisible,
      formInfo,
      getRegListCom,
      bgColor,
      LogoIcon,
      LogoWhiteIcon,
      headerClass,
      isSearchPage,
    };
  },
};
</script>

<style lang="less" scoped>
.header-wrapper {
  width: 100%;
  height: 72px;
  box-shadow: 0 20px 20px -20px rgba(46.000001057982445,107.00000122189522,173.00000488758087,0.10000000149011612);

  ::v-deep(.el-overlay) {
    z-index: 99999 !important;
  }

  .nav-before {
    height: 30px;
    width: 100%;
    line-height: 30px;
    background-color: #f6f6f6;
    font-size: 14px;
    font-weight: 200;
    color: #888888;

    .fixed-container {
      width: 1200px;
      margin: 0 auto;
      display: flex;

      .home-btn {
        display: flex;
        align-items: center;
        text-decoration: none;

        &:visited,
        &:link {
          color: #888888;
        }

        &:hover {
          color: #0398FE;
          font-weight: 400;
        }

        img {
          width: 16px;
          height: 16px;
          object-fit: contain;
          margin-right: 6px;
        }
      }

      .welcome {
        margin-left: 17px;
      }

      .tel {
        margin-left: 62px;
      }
    }
  }

  .nav {
    width: 100%;
    height: 72px;

    .nav-container {
      width: 1200px;
      //height: 72px;
      //
      //line-height: 72px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left-tool {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .link {
          display: flex;
          align-items: center;
          margin-right: 64px;
          .logo {
            width: 140px;
            height: 32px;
            object-fit: contain;
            padding-top: 10px;
          }
        }
        .tab-group {
          //width: 380px;
          height: 72px;
          display: grid;
          grid-template-columns: auto auto auto auto;
          grid-gap: 64px;
          justify-content: space-between;
          align-items: center;
          position: relative;
          overflow: hidden;
          .tabs-res {
            position: relative;
            width: fit-content;
            .active-bar {
              position: absolute;
              left: 50%;
              margin-top: 8px;
              transform: translateX(-50%);
              width: 12px;
              height: 2px;
              background: #fff;
              border-radius: 164px;
              transition: all ease 0.4s;
            }
            a {
              font-size: 16px;
              font-weight: 400;
              color: #ffffff;
              text-decoration: none;
              display: inline-block;
              box-sizing: border-box;
              text-align: center;
              transition: color ease 0.4s;

              &:visited {
                color: #fff;
              }

              &:hover {
                color: #0398FE;
              }
            }
          }
          .active {
            color: #fff !important;
            .active-bar {
              background: #fff !important;
            }

            //border-bottom: 2px solid #0398FE;
            a {
              color: #fff !important;
              &:visited {
                color: #fff;
              }

              &:hover {
                color: #fff;
              }
            }
          }
          .active-blue {
            color: #0398fe !important;
            .active-bar {
              background: #0398fe !important;
            }
            a {
              color: #0398fe !important;
              &:visited {
                color: #0398fe;
              }

              &:hover {
                color: #0398fe;
              }
            }
          }

          .tab-gray {
            color: #727889 !important;

            a {
              color: #727889 !important;
            }
          }
        }
      }
      .right-tool {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .search {
          position: relative;
          display: flex;
          align-items: center;
          margin-right: 18px;
          .basis-header-left {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 196px;
            height: 32px;
            background: #ffffff;
            border-radius: 16px;
            padding: 0 14px;
            font-size: 18px;
            color: #bababa;
            transition: all ease 0.3s;
            .el-icon-search {
              color: #0398fe;
              font-size: 14px;
              cursor: pointer;
            }
            input {
              outline: none;
              border: none;
              width: 100%;
              color: #0398fe;
              font-size: 14px;
              background-color: transparent;
              transition: all ease 0.3s;
            }

            input::-webkit-input-placeholder {
              color: #b7daff !important;
              font-size: 12px;
            }

            input::-ms-input-placeholder {
              color: #b7daff !important;
              font-size: 12px;
            }

            input::-moz-input-placeholder {
              color: #b7daff !important;
              font-size: 12px;
            }
          }
          .inputActive {
            input::-webkit-input-placeholder {
              //color: #0398fe !important;
            }
            input::-ms-input-placeholder {
              //color: #0398fe !important;
            }
            input::-moz-input-placeholder {
              //color: #0398fe !important;
            }
          }
          .btn {
            width: 64px;
            height: 40px;
            outline: none;
            //border: 1px solid #dddddd;
            border-radius: 0 20px 20px 0;
            background-color: #eeeeee;
            font-size: 18px;
            font-weight: bold;
            color: #bababa;
          }

          .btn:hover {
            color: #0398FE;
            cursor: pointer;
          }

          .drop-down {
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 32px;
            width: 296px;
            padding: 0 14px;
            //border-top: 1px solid #eeeeee;
            border-radius: 0 0 16px 16px;

            background: #ffffff;
            z-index: 2000;
            &::after {
              content: "";
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
              top: 0;
              bottom: 5px;
              right: 0;
              height: 1px;
              width: 268px;
              background-color: rgba(3, 152, 254, 0.1);
            }
            .history-title {
              display: flex;
              align-items: center;
              justify-content: space-between;
              font-weight: 400;
              font-size: 16px;
              color: #090909;
              margin-bottom: 16px;
              .hot-tool {
                width: 16px;
                height: 16px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                img {
                  margin-left: 6px;
                }
              }
              img {
                width: 16px;
                height: 16px;
                cursor: pointer;
              }
            }
            .mt-18 {
              margin-top: 18px;
            }
            .history-body {
              span {
                padding: 4px 10px;
                margin-right: 16px;
                margin-bottom: 16px;
                font-weight: 400;
                white-space: nowrap;
                cursor: pointer;
                background: rgba(3, 152, 254, 0.1);
                border-radius: 322px;
                font-size: 14px;
                color: #0398fe;
                display: inline-block;
              }

              span:hover {
                color: #0398FE;
              }
            }
          }
        }
        .user {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          font-weight: 600;

          .integral {
            margin-right: 18px;
            height: 32px;
            width: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            box-sizing: content-box;
            background: #ffffff;
            border-radius: 50%;
            img {
              width: 18px;
              height: 18px;
              object-fit: cover;
              flex-shrink: 0;
            }

            p {
              margin-top: 4px;
              height: 20px;
              line-height: 20px;
              font-size: 14px;
            }
          }

          .user-img {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            object-fit: cover;
            flex-shrink: 0;
            margin-right: 6px;
          }

          .user-p {
            margin-right: 6px;
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .login-btn {
          height: 32px;
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          box-sizing: content-box;
          background: #ffffff;
          border-radius: 50%;
          img {
            width: 18px;
            height: 18px;
            object-fit: cover;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

.search-page {
  background: #FFFFFF !important;
  box-shadow: 0px 4px 20px 0px rgba(46,107,173,0.1);
  opacity: 1;
}

::v-deep(.user-info) {
  height: 60px;
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

::v-deep(.user-ul) {
  list-style-type: none;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}

::v-deep(.user-li) {
  position: relative;
  list-style-type: none;
  margin: 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  img {
    width: 12px;
    height: 12px;
    margin-right: 8px;
  }
  //span:first-child {
  //  display: inline-block;
  //  text-align: center;
  //  padding-top: 5px;
  //}

  span {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}

.user-li:hover {
  color: #0398FE;

  svg path {
    fill: currentColor;
    color: #0398FE;
  }
}

.new-msg::before {
  content: "";
  display: block;
  position: absolute;
  left: 10px;
  top: 50%;
  width: 3px;
  height: 3px;
  margin-top: -7px;
  background: #05C064;
  border-radius: 50%;
}
</style>
<style>
.el-popover.popover-class {
  min-width: 106px;
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 6px;
  /*transform: translateX(1426px) !important;*/
  border: none !important;
}
</style>
